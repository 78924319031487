<template>
  <v-dialog
    :content-class="contentClass"
    v-model="computedIsVisible"
    :max-width="modalWidth"
    :persistent="persistent"
    :attach="attach"
    @click:outside.prevent="handleClickOutside"
    @keydown.esc="handleClickEsc"
    :scrollable="scrollable"
    @keydown.enter.prevent="onEnterClicked"
  >
    <v-card :max-height="maxHeight">
      <v-card-title
        :style="`box-shadow: ${headerShadow ? '0px 4px 4px rgba(0, 0, 0, 0.15)' : 'none'}`"
        class="font-weight-regular text-left"
      >
        <slot name="title">
          <span>{{ titleText }}</span>
          <v-spacer></v-spacer>
        </slot>
      </v-card-title>

      <v-card-text
        class="font--text maindialog-body-card-text"
        :style="`
          border-top: ${underlineTitle && !headerShadow ? '1px solid #cccccc' : 'none'};
          padding: ${bodyPadding ? bodyPadding : 'none'}
          `"
        style="width: 100%"
      >
        <slot name="body">
          <v-row no-gutters style="min-height: 60px">
            <v-col>
              <v-subheader class="text-left px-0 text-break text__wrapper">
                {{ bodyText }}
              </v-subheader>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>

      <v-card-actions
        :style="`border-top: ${underlineFooter ? '1px solid #cccccc' : 'none'};`"
        :class="{ large: large }"
      >
        <v-row no-gutters :class="{ 'flex-row-reverse': !isButtonsRight }" style="min-height: 28px">
          <slot name="buttons">
            <template v-if="useStickyButtons">
              <main-dialog-sticky-buttons @onClose="onClose" />
            </template>
            <template v-else>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <button-main-dialog
                  :class="{ 'ma-0': !isButtonsRight }"
                  v-if="hasDisagree"
                  cancel
                  @click="onClose"
                  :text="$i18n.t(disagreeText)"
                />
              </v-col>
              <v-col cols="auto" v-if="isAgreeVisible">
                <button-main-dialog
                  @click="$emit('agree')"
                  v-if="hasAgree"
                  :text="computedAgreeText"
                  :loading="loading"
                  :disabled="!isValid"
                />
              </v-col>
            </template>
          </slot>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sync } from 'vuex-pathify';

import { isFunction, isString } from 'lodash';
import ButtonMainDialog from './ButtonMainDialog';
import MainDialogStickyButtons from '@/components/MainDialogStickyButtons';

export default {
  name: 'MainDialog',
  components: {
    ButtonMainDialog,
    MainDialogStickyButtons,
  },
  props: {
    contentClass: {
      type: String,
      default: 'main-dialog',
    },
    attach: {
      type: [String, Boolean],
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Title that should not be translated, e.g layer card title.
     */
    title: {
      type: [Function, String, Boolean],
      default: false,
    },
    underlineTitle: {
      type: Boolean,
      default: false,
    },
    underlineFooter: {
      type: Boolean,
      default: false,
    },
    headerShadow: {
      type: Boolean,
      default: false,
    },
    body: {
      type: [Function, String, Boolean],
      default: '',
    },
    agreeText: {
      type: [Function, String, Boolean],
      default: false,
    },
    hasDisagree: {
      type: Boolean,
      default: true,
    },
    hasAgree: {
      type: Boolean,
      default: true,
    },
    modalWidth: {
      type: String,
      default: '24%',
      required: false,
    },
    bodyPadding: {
      type: String,
      required: false,
    },
    isButtonsRight: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '80vh',
    },
    disagreeText: {
      type: String,
      default: 'dialog.disagree',
    },
    isAgreeVisible: {
      type: Boolean,
      default: true,
    },
    useStickyButtons: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(nV) {
        if (nV) {
          this.modalOverlayLevel += 1;
        } else {
          this.modalOverlayLevel > 0 ? (this.modalOverlayLevel -= 1) : (this.modalOverlayLevel = 0);
        }
      },
    },
  },
  computed: {
    modalOverlayLevel: sync('tools/modalOverlayLevel'),
    headerElevationClass() {
      return this.headerShadow ? 'elevation-4' : 'elevation-0';
    },
    computedIsVisible: {
      get() {
        return this.isVisible;
      },
      set(nV) {
        this.$emit('update:isVisible', nV);
      },
    },
    titleSize() {
      return this.isSubtitle ? 'text-body-1' : 'text-h6';
    },
    titleText() {
      if (isFunction(this.title)) {
        return this.title();
      } else if (isString(this.title)) {
        return this.title;
      } else {
        return '';
      }
    },
    computedAgreeText() {
      if (isFunction(this.agreeText)) {
        return this.agreeText();
      } else if (isString(this.agreeText)) {
        return this.$i18n.t(this.agreeText);
      } else {
        return this.$i18n.t('dialog.agree');
      }
    },
    bodyText() {
      if (isFunction(this.body)) {
        return this.body();
      } else if (isString(this.body)) {
        return this.body;
      } else {
        return '';
      }
    },
  },
  methods: {
    onEnterClicked() {
      if (this.isValid) {
        this.$emit('agree');
      }
    },
    handleClickOutside() {
      return this.persistent ? null : (this.computedIsVisible = false);
    },
    handleClickEsc() {
      this.computedIsVisible = false;
    },
    onClose() {
      this.$emit('modalClosed');
      this.computedIsVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    min-width: 400px;
    max-height: 100%;
    .v-input {
      .v-input__icon.v-input__icon--clear {
        min-width: 21px;
        width: 21px;
        height: 21px;

        button {
          font-size: 17px;
        }
      }
    }

    & > .v-card {
      & > .v-card__title {
        padding: 17px 20px;
        z-index: 999;
        span {
          font-size: 20px;
        }
      }

      & > .v-card__text {
        padding-bottom: 0px;
        .text__wrapper {
          height: auto;
          max-height: 400px;
        }
      }

      & > .v-card__actions {
        padding: 21.5px 25.5px;
        &.large {
          padding: 21.5px 20px;
        }
      }
    }
  }
}
</style>

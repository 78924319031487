import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import VueGtag from 'vue-gtag';

import { capitalize } from '@/assets/js/utility';
import { camelCase } from 'lodash';

import dayjs from '@/plugins/dayjs';
import vuetify from '@/plugins/vuetify';
import utility from '@/plugins/utility';
import variables from '@/plugins/variables';
import i18n from '@/plugins/i18n';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'tiptap-vuetify/dist/main.css';
import 'video.js/dist/video-js.css';
import VuePapaParse from 'vue-papa-parse';
import '@/assets/fonts/style.css';
import '@/assets/styles/tailwind/binds.scss';
import '@/tailwind.css';
import PortalVue from 'portal-vue';

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(dayjs);
Vue.use(utility);
Vue.use(variables);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});
Vue.use(VuePapaParse);

const requireComponent = import.meta.glob('@/components/Base*.vue', { eager: true });

Object.entries(requireComponent).forEach(([fileName, module]) => {
  // Get PascalCase name of component
  const componentName = capitalize(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );

  Vue.component(componentName, module.default);
});

/**
 * VNode renderer global component, mainly for use in Vuetify's autocompletes
 * https://github.com/vuetifyjs/vuetify/issues/16644#issuecomment-1458227547
 */
Vue.component('VNodeRenderer', {
  name: 'VNodeRenderer',
  functional: true,
  render: (h, ctx) => (typeof ctx.props.vnodes == 'string' ? [ctx.props.vnodes] : ctx.props.vnodes),
});
// eslint-disable-next-line no-undef
console.log(`%cData aktualizacji: ${new Date(_DATE_BUILD_).toLocaleString()}`, 'font-size: 1.2em; font-weight: bold');
console.log(
  // eslint-disable-next-line no-undef
  `%cWersja front-endu: ${_FRONT_VERSION_DATE_}, ${_FRONT_VERSION_NUMBER_}`,
  'font-size: 1.2em; font-weight: bold'
);

(async () => {
  const localeCode = import.meta.env.VUE_APP_I18N_LOCALE;
  const locale = await import(`/vuetifyLocales/${localeCode}`).then(locale => locale.default);
  vuetify.preset.lang.locales[localeCode] = locale;
})();

async function getSettingsGoogleAnalytics() {
  try {
    const response = await fetch(`${import.meta.env.VUE_APP_API_URL}/settings/google_analytics?value_only=false`);
    const data = await response.json();
    return data.data.value.trim();
  } catch (e) {
    return import.meta.env.VITE_APP_GOOGLE_ANALYTICS;
  }
}
(async () => {
  //https://github.com/BuilderIO/partytown
  //https://analytics.google.com/analytics/web/#/a231951573w319349994p269217251/admin/tracking/tracking-code/
  //https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions
  //https://matteo-gabriele.gitbook.io/vue-gtag/v/master/
  //https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
  const ga4_id = await getSettingsGoogleAnalytics();
  if (ga4_id) {
    Vue.use(
      VueGtag,
      {
        appName: 'GIS BOX',
        config: {
          id: ga4_id,
          debug_mode: import.meta.env.MODE !== 'production',
        },
        params: {
          send_page_view: false,
          page_title: 'GIS BOX',
        },
        pageTrackerEnabled: true,
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
          return {
            page_title: document.title,
            page_path: to.path,
          };
        },
        deferScriptLoad: true,
      },
      router
    );
  }
})();

new Vue({
  name: 'mainApp',
  el: '#app',
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App),
});
